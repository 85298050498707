<template lang="pug">
  div(v-if="ready")
    div.all-notifications-list-wrapper
      div.all-notifications-container
          notifications-results(v-if="unseenNotifications.length" v-for="(item, index) in unseenNotifications" :key="index" :item="item" :hideMoreIcon="true")
          div.empty-list(v-if="!unseenNotifications.length") {{'ui.labels.list_is_empty' | translate}}
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import NotificationsResults from '@/components/common/notifications/NotificationsResults'

export default {
  name: 'FeedUnseen',

  components: {
    NotificationsResults
  },

  async mounted () {
    this.init()
  },

  data: () => ({
    ready: false
  }),

  methods: {
    ...mapActions('notifications', ['getUnseenNotifications']),

    async  init () {
      await this.getUnseenNotifications()
      this.ready = true
    }

  },

  computed: {
    ...mapGetters('notifications', ['unseenNotifications'])
  }

}

</script>

<style lang="scss" scoped>

</style>
